import { RESTAPI } from "../../rest-api";
import { axiosGet, axiosPost, axiosDelete } from "./http-request-service";

export const GetPCMLists = (startDate = "", endDate = "") => {
    let params = {};
    if (startDate && endDate) {
        params["start_date"] = startDate;
        params["end_date"] = endDate;
    }

    return axiosGet(RESTAPI.GETADVOCATE, params)
        .then((response) => {           
            return response;
        })
        .catch((err) => {

        });
};

export const AddPCMdata = (data) => {
  return axiosPost(RESTAPI.ADDORUPDATEPCM, data)
    .then((response) => {

      return response;
    })
    .catch((err) => {
 
    });
};

export const UploadDocument = (data) => {
    return axiosPost(RESTAPI.UPLOADPCMDOCUMENTS, data)
        .then((response) => {        
            return response;
        })
        .catch((err) => {

        });
};

export const GetPCMDetails = (case_number) => {
    return axiosGet(RESTAPI.GETPCMDETAILS, { id: case_number })
        .then((response) => {      
            return response;
        })
        .catch((err) => {

        });
};

export const DeleteDocuments = (case_number, file_name) => {
    return axiosDelete(RESTAPI.DELETEDOCUMENTS + "/" + case_number + "/" + file_name)
        .then((response) => {

            return response;
        })
        .catch((err) => {

        });
};

export const getNotifications = (case_adjournDate) => {
    // let params = {};
    // if (case_adjournDate) {
    //     params["case_adjourn_date"] = case_adjournDate;        
    // }
    return axiosGet(RESTAPI.NOTIFICATION)
        .then((response) => {           
            return response;
        })
        .catch((err) => {

        });
}