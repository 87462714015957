import { RESTAPI } from "../../rest-api";
import { axiosGet, axiosPost } from "./http-request-service";

const SendNotification = (isAllUSers, data) => {
    return axiosPost(RESTAPI.SEND_NOTIFICATION + "?all_users=" + isAllUSers, data)
        .then((response) => {
            return response;
        })
        .catch((err) => { });
};


const UpdateFCMToken = (data) => {
    return axiosPost(RESTAPI.UPDATE_FCM_TOKEN, data)
        .then((response) => {
            return response;
        })
        .catch((err) => { });
};

const WelcomeNotification = (queryParams = {}) => {
    console.log("send welcome");
    return axiosGet(RESTAPI.WELCOME_NOTI, queryParams)
        .then(response => {

            return response;
        })
        .catch(error => {

        })
}

const Logout = (queryParams = {}) => {
    console.log("call logout");
    return axiosGet('https://auth.bar-at.in.auth.ap-south-1.amazoncognito.com/logout?client_id=6513en1dgjfkndcit4gimnk3d5&logout_uri=http://localhost:3000/', queryParams)
        .then(response => {

            return response;
        })
        .catch(error => {

        })
}

export { SendNotification, UpdateFCMToken, WelcomeNotification, Logout };
