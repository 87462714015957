import { CONFIG } from "./config";

const DOMAIN = CONFIG.baseApiHost;

export const RESTAPI = {
  // LOGIN API
  LOGIN: DOMAIN + "/login",
  // PCM API'S
  GETADVOCATE: DOMAIN + "/pcm-list",
  ADDORUPDATEPCM: DOMAIN + "/pcm",
  UPLOADPCMDOCUMENTS: DOMAIN + "/upload-docs",
  GETPCMDETAILS: DOMAIN + "/pcm-details",
  DELETEDOCUMENTS: DOMAIN + "/delete-docs",
  CALENDAR: "https://www.googleapis.com/calendar/v3/calendars/primary/events",
  GETACCOUNTDETAILS: DOMAIN + "/adv-account-details",
  // GETDISTRICTS: DOMAIN + '/districts',
  GET_DISTRICTS_STATES: DOMAIN + "/district-states-v2",
  GETADMINADVOCATELIST: DOMAIN + "/get_advocate_list",
  UPDATEADVOCATEDATA: DOMAIN + "/update-advocate",
  GETADMINREPORTSDATA: DOMAIN + "/get_admin_reports",
  LISTDOCUMENTS: DOMAIN + "/documents/list",
  DOWNLOADDOCUMENTS: DOMAIN + "/document/download",
  GETDISTRICTS: DOMAIN + "/districts-v2",
  GETFREQUENTDOWNLOADS: DOMAIN + "/documents/frequent",
  DELETEFREQUENTDOWNLOAD: DOMAIN + "/documents/frequent/",
  // Citation endpoints
  CITATIONSEARCH: DOMAIN + "/citation/search/",
  CITATIONDOWNLOAD: DOMAIN + "/citation/download/",
  UPLOADADSIMAGES: DOMAIN + "/add/promo/image",
  LISTADS: DOMAIN + "/promo/images",
  DELETEADS: DOMAIN + "/delete/promo/",
  // Subscription endpoints
  ADDSUBSCRIPTION: DOMAIN + "/add/subscription",
  GETSUBCRIPTIONDETAILS: DOMAIN + "/subscription/details",
  GETSUBSCIPTIONAMOUNT: DOMAIN + "/subscription/amounts",
  GENERATEORDERID: DOMAIN + "/generate/order",
  ADDUSERROLE: DOMAIN + "/add_support_admin",
  NOTIFICATION: DOMAIN + "/notifications",
  SEND_NOTIFICATION : DOMAIN +'/send_notification_via_admin',
  
  UPDATE_FCM_TOKEN : DOMAIN + '/update_fcm_token',
  WELCOME_NOTI : DOMAIN + '/welcome_message'
};
