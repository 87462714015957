import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "./App/assets/Css/app.css";
import "./App/assets/Css/styles.css";
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router } from "react-router-dom";
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
    <Router>
        <App />
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.register()