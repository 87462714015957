import React, { useCallback, useEffect, useState } from "react";
import { AuthContext } from "../../../App";
import { Amplify, Auth, Hub } from "aws-amplify";
import awsconfig from "../../../aws-exports";
import LoginService from "../../Services/login-service";
import { GetCurrentUser, getFCMToken, setIsNewUser } from "../../Services/common-service";
import { useHistory } from "react-router-dom";
import { notifyError } from "../../Services/toaster-service";
import { Spinner } from 'react-bootstrap';
import { AddAccountDetails } from '../../Services/account-service';
import { WelcomeNotification } from "../../Services/notification-service";
import { getTokenT } from "../../Services/firebase";
// import { Auth } from "aws-amplify";

Amplify.configure(awsconfig);

const Login = () => {

    const currentUser = GetCurrentUser();
    const { state, dispatch } = React.useContext(AuthContext);
    let history = useHistory();
    const [user, setUser] = useState(null);
    let isLoading = localStorage.getItem('isLoading') ? localStorage.getItem('isLoading') : false;

    const [isTokenFound, setTokenFound] = useState(false);
    const [token, setToken] = useState('')

    // useEffect(() => {
    //     getTokenT(setTokenFound, setToken);
    // }, [])

    // useEffect(()=>{
    //     console.log("token",token);
    // },[token])
    
    const getUser = useCallback(async () => {
        return await Auth.currentAuthenticatedUser()
            .then((userData) => {
                const x = JSON.parse(
                    atob(userData.signInUserSession.idToken.jwtToken.split(".")[1])
                );
                localStorage.setItem("userData", JSON.stringify(x));
                if (!currentUser) {
                    async function login() {
                        var data = { fcm_token: getFCMToken() }

                        console.log("data",data);
                        await LoginService(data)
                            .then((response) => {
                                if (response.statusCode === '200') {
                                    // notifySuccess("Successfully logged in!");
                                    localStorage.setItem("handyyUser", JSON.stringify(response.body));
                                    localStorage.setItem("currentActiveItems", JSON.stringify({ ccn: "" }));
                                    if (response.body.user_type === "administrator") {
                                        // refreshTOken();
                                        if (response.body.isRegistered) {
                                            Auth.signOut();
                                        }
                                        setIsNewUser(false)
                                        history.push("/admin-dashboard");

                                    } else if (response.body.user_type === "support") {
                                        setIsNewUser(false)

                                        history.push("/support-dashboard");
                                    } else {

                                        if (response.body.isRegistered) {
                                            setIsNewUser(false)
                                            history.push("/home");
                                        } else {
                                            setIsNewUser(true)

                                            console.log("send welcome", response.body);
                                            WelcomeNotification({ user_id: response.body.user_id, userName: x.given_name })
                                            console.log("send welcome 2");

                                            history.push("/edit-account");
                                            addAccountDetails({
                                                basic_info: {
                                                    first_name: x.given_name,
                                                    last_name: x.family_name
                                                },
                                                enrollment_info: {
                                                    enrollment_bar: "",
                                                    enrollment_number: "",
                                                    enrollment_date: "",
                                                }
                                            });
                                        }
                                    }
                                }
                            })
                            .catch((error) => notifyError("Invalid Credentials!"));
                    }
                    login()
                }
                return userData;
            })
            .catch(() => console.log("Not signed in"));
    }, [currentUser, history]);

    const addAccountDetails = (data) => {
        AddAccountDetails(data).then((response) => {
            // if (response.statusCode === "200") {                
            // }
        });
    }

    // const refreshTOken = async () => {
    //     const cognitoUser = await Auth.currentAuthenticatedUser();
    //     const currentSession = await Auth.currentSession();
    //     cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
    //         console.log('session', err, session);
    //         const { idToken, refreshToken, accessToken } = session;
    //         // do whatever you want to do now :)
    //     });
    // }

    useEffect(() => {
        async function fetchData() {
            Hub.listen("auth", ({ payload: { event, data } }) => {
                switch (event) {
                    case "signIn":
                    case "cognitoHostedUI":
                        break;
                    case "signOut":

                        setUser(null);

                        dispatch({
                            type: "LOGOUT",
                            payload: null,
                        });
                        break;
                    case "signIn_failure":
                    case "cognitoHostedUI_failure":
                        console.log("Sign in failure", data);
                        break;
                    default:
                        break;
                }
            });

            await getUser().then((userData) => {
                setUser(userData ? userData : null);
                if (userData) {
                    dispatch({
                        type: "LOGIN",
                        payload: userData
                    });
                } else {

                    dispatch({
                        type: "LOGOUT",
                        payload: null
                    });
                }
            });
        }
        fetchData();
        return () => Hub.remove('auth')
    }, [dispatch, getUser]);

    const doLogin = () => {
        localStorage.setItem('isLoading', true);
        dispatch({
            type: "LOGIN_PROCESS",
            payload: null
        });
        Auth.federatedSignIn({ provider: "Google" })
    }

    return (
        <>
            {
                state.loading || isLoading ?
                    <div className="login-process-loader">
                        <Spinner className="login-spinner" animation="border" />
                        <div style={{ marginTop: '30px', fontSize: '22px' }}>Loading....</div>
                    </div>
                    :
                    <div className="layout-login-centered-boxed full-height">

                        <div
                            className="layout-login-centered-boxed__form card"
                            style={{
                                padding: "0px",
                                minHeight: "200px",
                                border: "none",
                                borderRadius: "6px",
                                background: "none",
                            }}
                        >
                            <div className="col-md-12 clearfix" style={{ padding: "0px" }}>
                                <div
                                    className="col-sm-6 clearfix login-logo"
                                    style={{
                                        float: "left",
                                        padding: "10px",
                                        background: "rgba(0, 24, 110, 1)",
                                        minHeight: "200px",
                                        borderRadius: "6px 0px 0px 6px",
                                        textAlign: 'center'
                                    }}
                                >
                                    <p>
                                        <img
                                            src={require("../../assets/images/logo-login.png")}
                                            style={{ maxWidth: "229px", textAlign: "center" }}
                                            alt="Handyy Logo"
                                        />
                                    </p>
                                </div>
                                <div
                                    className="col-sm-6 clearfix login-google"
                                    style={{
                                        float: "left",
                                        padding: "10px",
                                        background: "rgba(197, 0, 23, 0.9)",
                                        minHeight: "200px",
                                        borderRadius: "0px 6px 6px 0px",
                                    }}
                                >
                                    <p className="col-md-12 p-0">
                                        <br />
                                        <br />
                                        <span
                                            style={{
                                                color: "#fff",
                                                fontSize: "18px",
                                                fontWeight: "bold",
                                                textAlign: "center",
                                            }}
                                        >
                                            Glad to see you here!
                                        </span>
                                    </p>
                                    <br />
                                    {
                                        user ?
                                            (
                                                <button className="btn btn-light btn-block" onClick={() => Auth.signOut()}>
                                                    <span className="fab fa-google mr-2"></span>
                                                    Sign Out {user.getUsername()}
                                                </button>
                                            )
                                            :
                                            // state.loading || isLoading ? <div style={{textAlign:'center'}}><Spinner animation="border" style={{color: 'white'}}/></div> :
                                            (
                                                <button className="btn btn-light btn-block" onClick={() => doLogin()}>
                                                    {/* <span className="fa fa-google mr-2"></span> */}
                                                    <svg aria-hidden="true" className="native svg-icon iconGoogle mr-2" style={{ marginTop: '-2px' }} width="14" height="14" viewBox="0 0 18 18">
                                                        <path d="M16.51 8H8.98v3h4.3c-.18 1-.74 1.48-1.6 2.04v2.01h2.6a7.8 7.8 0 002.38-5.88c0-.57-.05-.66-.15-1.18z" fill="#4285F4"></path>
                                                        <path d="M8.98 17c2.16 0 3.97-.72 5.3-1.94l-2.6-2a4.8 4.8 0 01-7.18-2.54H1.83v2.07A8 8 0 008.98 17z" fill="#34A853"></path>
                                                        <path d="M4.5 10.52a4.8 4.8 0 010-3.04V5.41H1.83a8 8 0 000 7.18l2.67-2.07z" fill="#FBBC05"></path>
                                                        <path d="M8.98 4.18c1.17 0 2.23.4 3.06 1.2l2.3-2.3A8 8 0 001.83 5.4L4.5 7.49a4.77 4.77 0 014.48-3.3z" fill="#EA4335"></path>
                                                    </svg>
                                                    Continue with Google
                                                </button>
                                            )

                                    }
                                </div>
                            </div>
                        </div>

                    </div>
            }
        </>
    );
};
export default Login;
