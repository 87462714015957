import React, { useEffect, useCallback, useState } from "react";
import {
  Container,
  Navbar,
  Form,
  FormControl,
  Button,
  InputGroup,
  Dropdown,
  Image,
  Modal,
} from "react-bootstrap";
import logo from "../assets/images/logo.png";
import DefaultUser from "../assets/images/avatar/demi.png";
import { useHistory } from "react-router-dom";
import {
  Search,
  Notifications,
  Dvr,
  ExitToApp,
  AccountCircle,
} from "@material-ui/icons";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { Auth } from "aws-amplify";
import {
  GetCurrentUser, getFCMToken
} from "../Services/common-service";
import { AddRole } from "../Services/admin-service";
import { notifyInfo } from "../Services/toaster-service";
import { getNotifications } from "../Services/pcm-service";
import Moment from "moment";
import NotifIcon from "../assets/images/message-square.svg";
import { notifySuccess, notifyError } from "../Services/toaster-service";
import { Logout, UpdateFCMToken } from "../Services/notification-service";
import { useCookies } from "react-cookie";
import Cookies from 'universal-cookie';

const Header = ({ toggleSidebar }) => {
  const initialFormData = {
    email: "",
    type: "support",
  };
  const localuserData = JSON.parse(localStorage.getItem("userData"));
  const user = localuserData !== undefined ? localuserData : null;
  const userDetails = GetCurrentUser();
  const user_type = userDetails != null ? userDetails.user_type : "";
  const [formData, setFormData] = useState(initialFormData);
  const [searchText, setSearchText] = useState("");
  const [notifications, setNotifications] = useState([]);
  const [show, setShow] = useState(false);
  const [isFormValid, setFormValidation] = useState(false);

  const cookies = new Cookies();

  let history = useHistory();
  // console.log(history);
  const goToCase = () => {
    if (searchText !== "") {
      localStorage.setItem(
        "globalSearchCase",
        JSON.stringify({ globalSearch: true, searchText })
      );
      history.push("/pcm");
      setSearchText("");
    } else notifyInfo("Please enter search text!");
  };

  const onEnter = (e) => {
    if (e.key === "Enter") goToCase();
  };

  const getPCMNotifications = useCallback(() => {
    getNotifications(Moment().format("YYYY-MM-DD"))?.then((response) => {
      if (response.statusCode === "200") setNotifications(response.body.notification_list);
    });
  }, []);

  useEffect(() => {
    if (user_type === "advocate") getPCMNotifications();
  }, [getPCMNotifications, user_type]);

  useEffect(() => {
    var data = { fcm_token: getFCMToken() }

    UpdateFCMToken(data).then(res => {
    }).catch(err => console.log(err))
  }, [])

  const PCMNotifications = notifications && typeof notifications === 'object' && notifications?.length > 0 && notifications?.map((item, i) => (
    // console.log(item)
    <div key={i}>
      <Dropdown.Item
        href="#"
        className="d-flex"
        style={{ alignItems: "center", cursor: "pointer" }}
        key={{ i }}
      // onClick={() => viewCase(item.id)}
      >
        <div className="mr-3">
          <div
            className="avatar avatar-sm"
            style={{ width: "32px", height: "32px" }}
          >
            <img src={NotifIcon} alt="Notif" className="avatar-img" />
          </div>
        </div>
        <div className="flex">
          {/* <span>{item.case_num}</span> */}
          <span>{item.title}</span>
          {/* <span></span> */}
          <br />

          <span>{item.subtitle}</span>

          <br />

          <span>{item.message_data}</span>

          {/* <small className="text-muted">{item.party[0]["name"]}</small> */}
        </div>
      </Dropdown.Item>
      <hr style={{ display: 'block', margin: '6px 0' }} />
    </div>
  ));

  const redirecToHome = (userType) => {
    switch (userType) {
      case "advocate":
        history.push('/home');
        break;
      case "administrator":
        history.push("/admin-dashboard");
        break;
      case "support":
        history.push("/support-dashboard");
        break;
      default:
        break;
    }
  }

  // const viewCase = (case_id) => {
  //     setCurrentCaseNumber(case_id);
  //     history.push("/view-pcm");
  // };

  const handleInputChange = (event) => {
    checkFormValidation(event);
    setFormData({
      ...formData,
      // Trimming any whitespace .trim()
      [event.target.name]: event.target.value,
    });
  };

  const checkFormValidation = (event) => {
    switch (event.target.name) {
      case "email":
        if (
          new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i).test(
            event.target.value
          )
        ) {
          setFormValidation(true);
        } else {
          setFormValidation(false);
        }
        break;
      default:
        break;
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleOpenAddRole = () => {
    setShow(true);
  };

  const handleAddRole = (event) => {
    event.preventDefault();
    let data = {
      email: formData.email,
      type: formData.type,
    };
    AddRole(data)
      .then((response) => {
        if (response.statusCode === "200") {
          notifySuccess(response.body.message);
          setFormValidation(false);
          setShow(false);
          setFormData({
            ...formData,
            email: "",
            type: "support"
          });
        }
      })
      .catch((error) => notifyError("Error on adding user!"));
  };

  useEffect(() => {
    // cookies.set('cognito', '', {path: "/", domain: ".auth.bar-at.in"});

    console.log("cookies",cookies.getAll());

  }, [])


  async function logout() {


    console.log("in logout");
    try {

      // removeCookie('cognito', { path: '/' });
      // removeCookie('XSRF-TOKEN', { path: '/' });

      cookies.remove("cognito", {path: "/", domain: ".auth.bar-at.in"})  

      await Auth.signOut();
      localStorage.clear()
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  // const logout = async() => {

  //   console.log("in logout");
  //    Auth.signOut(({ global: true })).then(res=> console.log(res)).catch(err=> console.log(err))
  //   localStorage.clear()
  // }

  return (
    <div>
      <Navbar
        expand="sm"
        variant="dark"
        bg="dark"
        fixed="top"
        className="handyy-header"
      >
        <Container fluid style={{ justifyContent: "flex-start" }}>
          <Navbar.Toggle
            className="navbar-toggler-left d-block d-md-none"
            aria-controls="basic-navbar-nav"
            onClick={toggleSidebar}
          />
          <Navbar.Brand style={{ cursor: "pointer" }} onClick={() => redirecToHome(user_type)}>
            <Image src={logo} style={{ maxHeight: "40px", width: "225px" }} />
          </Navbar.Brand>
          {user_type === "advocate" ? (
            <Navbar.Collapse id="basic-navbar-nav" className="d-sm-flex flex">
              <Form
                inline
                className="search-form d-none d-sm-flex flex handyy-search-form"
              >
                <InputGroup style={{ width: "100%" }}>
                  <FormControl
                    type="text"
                    placeholder="Search your case"
                    className="mr-sm-2 handyy-searchbar"
                    value={searchText}
                    onKeyDown={onEnter}
                    onChange={(e) => setSearchText(e.target.value)}
                    style={{ paddingLeft: "15px" }}
                    disabled={
                      userDetails.subscription_type === "Basic" ? true : false
                    }
                  />
                  <InputGroup.Append>
                    <Button
                      variant="outline-secondary"
                      className="handy-search-btn"
                      onClick={() => goToCase()}
                      disabled={
                        userDetails.subscription_type === "Basic" ? true : false
                      }
                    >
                      <Search className="search-icon"></Search>
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </Form>
            </Navbar.Collapse>
          ) : (
            ""
          )}
          <ul className="nav navbar-nav ml-auto d-none d-md-flex">
            <li className="nav-item dropdown">
              <Dropdown>
                <Dropdown.Toggle
                  className="nav-link"
                  id="dropdown-basic"
                  style={{
                    background: "transparent",
                    border: "none",
                    boxShadow: "none",
                  }}
                >
                  <Notifications
                    className={`material-icons nav-icon navbar-notifications-indicator ${user_type === "advocate" ? "" : "d-none"
                      }`}
                    style={{ fontSize: "22px" }}
                  ></Notifications>
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-menu-right navbar-notifications-menu">
                  <Dropdown.Item className="d-flex align-items-center py-2">
                    <span className="flex navbar-notifications-menu__title m-0">
                      Notifications
                    </span>
                    {/* <span className="text-muted">
                                <small style={{cursor:'pointer'}}>{isToday ? "View Tomorrow's Cases" : "View Today's Cases"}</small>
                            </span> */}
                  </Dropdown.Item>
                  <div
                    className="navbar-notifications-menu__content"
                    data-perfect-scrollbar
                  >
                    <div className="py-2">
                      {PCMNotifications?.length > 0 ? (
                        PCMNotifications
                      ) : (
                        <p style={{ padding: "0 1.25rem" }}>
                          No Notifications Yet
                        </p>
                      )}
                    </div>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
          <Dropdown
            className="d-none d-sm-flex dd-right navbar-height align-items-center"
            style={{ borderLeft: "1px solid rgba(0, 0, 0, 0.34)" }}
          >
            <Dropdown.Toggle
              id="dropdown-basic"
              className="navDropdown e-caret-hide"
            >
              <span className="mr-2 d-flex-inline">
                <span className="text-light">
                  {user
                    ? user.given_name + " " + user["family_name"][0] + "."
                    : "User"}
                </span>
              </span>
              <img
                src={user ? user.picture : DefaultUser}
                className="rounded-circle"
                width="32"
                alt="Profile"
              />
            </Dropdown.Toggle>
            <Dropdown.Menu id="dropdownMenu">
              <Dropdown.Item
                href="/ads"
                className={`dropdown-item ${user_type === "administrator" ? "" : "d-none"
                  }`}
              >
                <Dvr className="material-icons" id="navBarIcons" />
                Create Ads
              </Dropdown.Item>
              <Dropdown.Item
                className={`dropdown-item ${user_type === "administrator" ? "" : "d-none"
                  }`}
                onClick={handleOpenAddRole}
              >
                <PersonAddIcon className="material-icons" id="navBarIcons" />
                Add User
              </Dropdown.Item>
              <Dropdown.Item
                href="/subscription"
                className={`dropdown-item ${user_type === "advocate" ? "" : "d-none"
                  }`}
              >
                <Dvr className="material-icons" id="navBarIcons" />
                My Plan
              </Dropdown.Item>
              <Dropdown.Item
                href="/edit-account"
                className={`dropdown-item ${user_type === "advocate" ? "" : "d-none"
                  }`}
              >
                <AccountCircle className="material-icons" id="navBarIcons" />
                My Account
              </Dropdown.Item>
              <Dropdown.Divider
                className={`${user_type === "support" ? "d-none" : ""}`}
              />
              <Dropdown.Item href="/" onClick={() => logout()}>
                <ExitToApp className="material-icons" id="navBarIcons" />
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Container>
      </Navbar>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className="border-bottom-0 pr-4 pl-4">
          <Modal.Title>Add User</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-3 pr-4 pl-4 mb-3">
          <Form>
            <div className="form-group">
              <input
                id="email"
                name="email"
                className="form-control form-white"
                placeholder="Email"
                type="text"
                onChange={handleInputChange}
              />
              <div>
                <label htmlFor="type">Select Role</label>
                <br />
                <Form.Control
                  as="select"
                  name="type"
                  className="my-1 mr-sm-2"
                  id="type"
                  custom
                  onChange={handleInputChange}
                >
                  <option value="support">Support</option>
                  <option value="administrator">Administrator</option>
                </Form.Control>
              </div>
            </div>
          </Form>
          <Button
            className="btn btn-success ml-1"
            onClick={handleAddRole}
            disabled={!isFormValid}
          >
            Add
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Header;
