import axios from 'axios';
import { CONFIG } from '../../config';
import { notifyError } from '../Services/toaster-service';
import { Auth } from "aws-amplify";

let tokens = { idToken: '', accessToken: ''};

const http = axios.create({
    baseURL: CONFIG.baseApiHost
})

const getTokens = () => {
    return Auth.currentAuthenticatedUser()
    .then((userData) => {
        tokens.idToken = userData.signInUserSession.idToken.jwtToken;
        tokens.accessToken = userData.signInUserSession.accessToken.jwtToken;
        return tokens;
    })
    .catch(() => console.log("Not signed in"));
}

const axiosRequestHandler = (request) => {
    return getTokens().then(({idToken, accessToken}) => {
        request.headers['Authorization'] = 'Bearer ' + idToken;
        request.headers['AccessToken'] = accessToken;
        request.headers['Content-Type'] = 'application/json';
        return request;
    })
    .catch((error)=> {
 
        return Promise.reject(error);
    });    
}

const axiosResponseHandler = (response) => {

    if(response.status === 200){
        const response_data  = response.data;
        if(response_data.statusCode === '200'){        
            const response_body = JSON.parse(response_data.body);
            return {body: response_body, statusCode: response_data.statusCode};
        }else{
            if(response_data.statusCode !== '404')
                notifyError(response_data.body);
            return {body:response_data.body, statusCode: response_data.statusCode};
        }
    }else{
        notifyError('Something went wrong!');
        return null;
    }

}

const axiosResponseErrorHandler = (error) => {

    const { status, data } = error.response;

    switch (status) {
        case 400:
        case 401:
        case 402:
        case 403:
        case 405:
        case 406:
        case 407:
            notifyError(data.Message);
            break;
        case 404:
            notifyError(data.Message);            
            break;
        case 500:
            notifyError(data.Message);
            break;    
        default:
            notifyError('Something went wrong!');
            break;  
    }

}

http.interceptors.request.use(
    request => axiosRequestHandler(request),
    error => Promise.reject(error)
);

http.interceptors.response.use(
    response => axiosResponseHandler(response),
    error => axiosResponseErrorHandler(error)
);

export const axiosGet = (api, queryParams={}) => {
    return http.get(api, {params: queryParams})
    .then(response => {

        return response;
    })
    .catch(error => {

    })
}

export const axiosPost = (api, body = {}) => {

    return http.post(api, JSON.stringify(body))
    .then(response => {

        return response;
    })
    .catch(error => {
      
    })
}

export const axiosDelete = (api, body = {data: {}}) => {    
    return http.delete(api, body)
    .then(response => {        
        return response;
    })
    .catch(error => {

    })
}
