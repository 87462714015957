import { RESTAPI } from "../../rest-api";
import { axiosPost } from './http-request-service';

const LoginService = (data) => {

    return axiosPost(RESTAPI.LOGIN,data)  
    .then((response) => {                
        return response;
    })
    .catch((err) => {
    });
    
};

export default LoginService;
