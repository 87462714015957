import { RESTAPI } from "../../rest-api";
import { axiosGet, axiosPost } from "./http-request-service";

export const AddSubscription = (body) => {
  return axiosPost(RESTAPI.ADDSUBSCRIPTION, body)
    .then((response) => {
      return response;
    })
    .catch((err) => {});
};

export const GetSubscriptionDetails = () => {
  return axiosGet(RESTAPI.GETSUBCRIPTIONDETAILS)
    .then((response) => {
      return response;
    })
    .catch((err) => {});
};

export const GetSubscriptionAmount = () => {
  return axiosGet(RESTAPI.GETSUBSCIPTIONAMOUNT)
    .then((response) => {
      return response;
    })
    .catch((err) => {});
};

export const GenerateOrderId = (body) => {
  return axiosPost(RESTAPI.GENERATEORDERID, body)
    .then((response) => {
      return response;
    })
    .catch((err) => {});
};
