import React, { Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { Spinner } from "react-bootstrap";

const Dashboard = React.lazy(() => import('../src/App/Components/Lawyer/dashboard'));
const AdminDashboard = React.lazy(() => import('./App/Components/Admin/admin-dashboard'));
const Ads = React.lazy(() => import('./App/Components/Admin/ads'));
const PCMList = React.lazy(() => import('./App/Components/Lawyer/PCM/PCMList'));
const EditPCM = React.lazy(() => import('./App/Components/Lawyer/PCM/EditPCM'));
const ViewPCM = React.lazy(() => import('./App/Components/Lawyer/PCM/view-pcm'));
const Account = React.lazy(() => import('./App/Components/Lawyer/account'));
const Citation = React.lazy(() => import('./App/Components/Lawyer/citation'));
const Documenting = React.lazy(() => import('./App/Components/Lawyer/documenting'));
const Calendar = React.lazy(() => import('./App/Components/Lawyer/calendar'));
const Subscription = React.lazy(() => import('./App/Components/Lawyer/subscription'));
const NotFound = React.lazy(() => import('./App/Shared-Components/not-found'));
const SupportDashboard = React.lazy(() => import('./App/Components/Support/support-dashboard'));
// import Dashboard from "../src/App/Components/Lawyer/dashboard";
// import AdminDashboard from "./App/Components/Admin/admin-dashboard"
// import Ads from "./App/Components/Admin/ads"
// import PCMList from "./App/Components/Lawyer/PCM/PCMList";
// import EditPCM from "./App/Components/Lawyer/PCM/EditPCM";
// import ViewPCM from "./App/Components/Lawyer/PCM/view-pcm";
// import Account from './App/Components/Lawyer/account';
// import Citation from "./App/Components/Lawyer/citation";
// import Documenting from "./App/Components/Lawyer/documenting";
// import Calendar from "./App/Components/Lawyer/Calendar";
// import Subscription from "./App/Components/Lawyer/subscription";
// import NotFound from './App/Shared-Components/not-found';
// import SupportDashboard from "./App/Components/Support/support-dashboard";


const Routes = (props) => {

    return (
        <>
            <Suspense fallback={
                <div style={{ textAlign: "center" }}>
                    <Spinner animation="border" />
                </div>}>
                <Switch>                
                    <Route exact path="/home" component={Dashboard} />
                    <Route exact path="/calendar" component={Calendar} />
                    <Route exact path="/pcm" component={PCMList} />               
                    <Route exact path="/pcm-new" component={EditPCM} />
                    <Route exact path="/view-pcm" component={ViewPCM} />
                    <Route exact path="/citation" component={Citation} />
                    <Route exact path="/documenting" component={Documenting} />
                    <Route exact path="/subscription" component={Subscription} />
                    <Route exact path="/edit-account" component={Account} />                                    
                    <Route exact path="/admin-dashboard" component={AdminDashboard} /> 
                    <Route exact path="/ads" component={Ads} />                  
                    <Route exact path="/support-dashboard" component={SupportDashboard} /> 
                    <Route component={NotFound} />
                </Switch>
                <ToastContainer />
            </Suspense>
        </>
    );
};

export default Routes;
