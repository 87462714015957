import { RESTAPI } from "../../rest-api";
import { axiosGet } from "./http-request-service";
import React from 'react';

export let globalSearchRef = React.createRef();

export const GetCurrentUser = () => {
  const user = localStorage.getItem("handyyUser");
  return user !== undefined
    ? JSON.parse(localStorage.getItem("handyyUser"))
    : null;
};

export const GetCognitoUserData = () => {
  const cognitoUser = localStorage.getItem("userData");
  return cognitoUser !== undefined
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
};

export const GetCurrentUserAdvocateId = () => {
  const user = GetCurrentUser();
  if (user) return user.id;
  else return null;
};

export const isNumberKey = (evt) => {

  var charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) evt.preventDefault();

};

export const getDistricts = () => {
  return axiosGet(RESTAPI.GETDISTRICTS)
    .then((response) => {
      return response;
    })
    .catch((err) => {

    });
};

export const getDistrictsStates = () => {
  return axiosGet(RESTAPI.GET_DISTRICTS_STATES)
    .then((response) => {
      return response;
    })
    .catch((err) => {

    });
};

export const setFCMToken = (token) => {
  localStorage.setItem("token", token);
}

export const getFCMToken = () => {
  const token = localStorage.getItem("token");
  return token !== undefined ? token : null;
}

export const setIsNewUser = (isNewUser) => {
  localStorage.setItem("isNewUser", isNewUser);
}

export const getIsNewUser = () => {
  const isNewUser = localStorage.getItem("isNewUser");
  
  return isNewUser !== undefined ? isNewUser : false;
}


export const getDistrictsOfState = (queryParams) => {
  return axiosGet(RESTAPI.GETDISTRICTS, queryParams)
    .then((response) => {
      return response;
    })
    .catch((err) => {

    });
};

export const getActiveItems = () => {
  const activeItems = localStorage.getItem("currentActiveItems");
  return activeItems !== undefined
    ? JSON.parse(localStorage.getItem("currentActiveItems"))
    : null;
};

export const getCurrentActiveCase = () => {
  const activeItem = getActiveItems();
  return activeItem ? activeItem.ccn : "";
};

export const setCurrentCaseNumber = (case_number) => {
  let activeItem = getActiveItems();
  activeItem.ccn = case_number;
  localStorage.setItem("currentActiveItems", JSON.stringify(activeItem));
};

export const setSubscriptionType = (subscription_type) => {
  let user = GetCurrentUser();
  user.subscription_type = subscription_type;
  localStorage.setItem("handyyUser", JSON.stringify(user));
}

export const CalculateCourtFeeForCivil = (value) => {
  if (value === 0 || value === '') return 0;
  if (value <= 100)
    return 4;
  else if (value > 100 && value <= 15000)
    return parseInt(0.04 * value);
  else if (value > 15000 && value <= 50000) {
    const sub = value - 15000;
    const fee = parseInt(0.04 * 15000) + parseInt(0.08 * sub);
    return fee;
  }
  else if (value > 50000 && value <= 1000000) {
    const sub = value - 50000;
    const fee = CalculateCourtFeeForCivil(50000) + parseInt(0.1 * sub);
    return fee;
  }
  else if (value > 1000000 && value <= 10000000) {
    const sub = value - 1000000;
    const fee = CalculateCourtFeeForCivil(1000000) + parseInt(0.08 * sub);
    return fee;
  }
  else {
    const sub = value - 10000000;
    const fee = CalculateCourtFeeForCivil(10000000) + parseInt(0.01 * sub);
    return fee;
  }
}

export const CalculateCourtFeeForConsumer = (value) => {
  if (value === 0 || value === '') return 0;
  if (value <= 100000)
    return 100;
  else if (value > 100000 && value <= 500000)
    return 200;
  else if (value > 500000 && value <= 1000000)
    return 400;
  else if (value > 1000000 && value <= 2000000)
    return 500;
  else if (value > 2000000 && value <= 5000000)
    return 2000;
  else if (value > 5000000 && value <= 10000000)
    return 4000;
  else
    return 5000;
}

var num = "Zero One Two Three Four Five Six Seven Eight Nine Ten Eleven Twelve Thirteen Fourteen Fifteen Sixteen Seventeen Eighteen Nineteen".split(" ");
var tens = "Twenty Thirty Forty Fifty Sixty Seventy Eighty Ninety".split(" ");

export const number2words = (n) => {
  if (n < 20) return num[n];
  var digit = n % 10;
  if (n < 100) return tens[~~(n / 10) - 2] + (digit ? " " + num[digit] : "");
  if (n < 1000) return num[~~(n / 100)] + " Hundred" + (n % 100 === 0 ? "" : " " + number2words(n % 100));
  return number2words(~~(n / 1000)) + " Thousand" + (n % 1000 !== 0 ? " " + number2words(n % 1000) : "");
}

export const getGlobalSearchText = () => {
  const item = localStorage.getItem("globalSearchCase");
  return item !== undefined
    ? JSON.parse(localStorage.getItem("globalSearchCase"))
    : null;
}

export const setGlobalSearchText = () => {
  const item = getGlobalSearchText();
  item.globalSearch = false;
  item.searchText = '';
  localStorage.setItem('globalSearchCase', JSON.stringify(item))
}