import React, { useState, useCallback, useEffect } from "react";
import { Nav } from "react-bootstrap";
import { Dvr } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import { Form, Button, Modal } from "react-bootstrap";
import { GetCurrentUser, GetCognitoUserData } from "../Services/common-service";
import DashboardIcon from "@material-ui/icons/Dashboard";
import EventIcon from "@material-ui/icons/Event";
import FormatQuoteIcon from "@material-ui/icons/FormatQuote";
import DescriptionIcon from "@material-ui/icons/Description";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { AddRole } from "../Services/admin-service";
import { notifySuccess, notifyError } from "../Services/toaster-service";
import { GetSubscriptionDetails } from '../Services/subscription-service';

const Sidebar = ({ toggleSidebar, isMobile }) => {
  const initialFormData = {
    email: "",
    type: "support",
  };
  const showSidebar = () => {};
  const user = GetCurrentUser();
  const user_type = user ? user.user_type : "";
  const subcriptiontype = user ? user.subscription_type : "Freemium";
  const cognitoUser = GetCognitoUserData();
  const [formData, setFormData] = useState(initialFormData);
  const [show, setShow] = useState(false);
  const [isFormValid, setFormValidation] = useState(false);
  const [isVAS, setIsVAS] = useState(false);

    const checkIsVasActive = useCallback(() => {
        if(user_type === "advocate"){
            GetSubscriptionDetails().then((response) => {
                if (response.statusCode === "200" && response.body.subscription_details.is_vas === "1") {                                
                    setIsVAS(true);
                }else {
                    setIsVAS(false);
                }
            })
        }
    }, [user_type])

    useEffect(() => {        
        checkIsVasActive();
    }, [checkIsVasActive])

  const handleInputChange = (event) => {
    checkFormValidation(event);
    setFormData({
      ...formData,
      // Trimming any whitespace .trim()
      [event.target.name]: event.target.value,
    });
  };

  const checkFormValidation = (event) => {
    switch (event.target.name) {
      case "email":
        if (
          new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i).test(
            event.target.value
          )
        ) {
          setFormValidation(true);
        } else {
          setFormValidation(false);
        }
        break;
      default:
        break;
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleOpenAddRole = () => {
    setShow(true);
  };

  const handleAddRole = (event) => {
    event.preventDefault();
    let data = {
      email: formData.email,
      type: formData.type,
    };
    AddRole(data)
      .then((response) => {
        if (response.statusCode === "200") {
          notifySuccess(response.body.message);
          setFormValidation(false);
          setShow(false);
          setFormData({
            ...formData,
            email: "",
            type: "support",
          });
          toggleSidebar();
        }
      })
      .catch((error) => notifyError("Error on adding user!"));
  };

  const logout = () => {
    console.log("in logout");
    Auth.signOut(({ global: true })).then(res=> console.log(res)).catch(err=> console.log(err))
    localStorage.clear()
  }

  return (
    <>
      <div className="sidebar-heading">
        Hello, {cognitoUser ? cognitoUser.given_name : "User"}
      </div>
      <Nav
        defaultActiveKey="/home"
        className={`flex-column custom-mb1 ${
          subcriptiontype === "Basic" ? "d-none" : ""
        }`}
      >
        {user_type === "advocate" ? (
          <>
            <Link
              to="/home"
              className="sidebar-menu-button"
              onClick={isMobile ? toggleSidebar : showSidebar}
            >
              <DashboardIcon className="sidebar-menu-icon sidebar-menu-icon--left material-icons"></DashboardIcon>
              <span className="sidebar-menu-text">Dashboard</span>
            </Link>
            <Link
              to="/calendar"
              className="sidebar-menu-button"
              onClick={isMobile ? toggleSidebar : showSidebar}
            >
              <EventIcon className="sidebar-menu-icon sidebar-menu-icon--left material-icons"></EventIcon>
              <span className="sidebar-menu-text">Calendar</span>
            </Link>
            <Link
              to="/pcm"
              className="sidebar-menu-button"
              onClick={isMobile ? toggleSidebar : showSidebar}
            >
              <Dvr className="sidebar-menu-icon sidebar-menu-icon--left material-icons"></Dvr>
              <span className="sidebar-menu-text">PCM</span>
            </Link>
            <Link
              to="/citation"
              className="sidebar-menu-button"
              onClick={isMobile ? toggleSidebar : showSidebar}
            >
              <FormatQuoteIcon className="sidebar-menu-icon sidebar-menu-icon--left material-icons"></FormatQuoteIcon>
              <span className="sidebar-menu-text">Citation</span>
            </Link>
            <Link
              to="/documenting"
              className="sidebar-menu-button"
              onClick={isMobile ? toggleSidebar : showSidebar}
            >
              <DescriptionIcon className="sidebar-menu-icon sidebar-menu-icon--left material-icons"></DescriptionIcon>
              <span className="sidebar-menu-text">Documenting</span>
            </Link>
          </>
        ) : (
          ""
        )}
        {isMobile ? (
          <>
            <Link
              to="/edit-account"
              className={`sidebar-menu-button ${
                user_type === "advocate" ? "" : "d-none"
              }`}
              onClick={isMobile ? toggleSidebar : showSidebar}
            >
              <AccountCircleIcon className="sidebar-menu-icon sidebar-menu-icon--left material-icons"></AccountCircleIcon>
              <span className="sidebar-menu-text">Account</span>
            </Link>
            <Link
              to="/admin-dashboard"
              className={`sidebar-menu-button ${
                user_type === "administrator" ? "" : "d-none"
              }`}
              onClick={isMobile ? toggleSidebar : showSidebar}
            >
              <DashboardIcon className="sidebar-menu-icon sidebar-menu-icon--left material-icons"></DashboardIcon>
              <span className="sidebar-menu-text">Dashboard</span>
            </Link>
            <Link
              to="/ads"
              className={`sidebar-menu-button ${
                user_type === "administrator" ? "" : "d-none"
              }`}
              onClick={isMobile ? toggleSidebar : showSidebar}
            >
              <Dvr className="sidebar-menu-icon sidebar-menu-icon--left material-icons"></Dvr>
              <span className="sidebar-menu-text">Create Ads</span>
            </Link>
            <Button
              className={`sidebar-menu-button ${
                user_type === "administrator" ? "" : "d-none"
              }`}
              onClick={isMobile ? handleOpenAddRole : showSidebar}
            >
              <Dvr className="sidebar-menu-icon sidebar-menu-icon--left material-icons"></Dvr>
              <span className="sidebar-menu-text">Add User</span>
            </Button>
            <Link
              to="/"
              className="sidebar-menu-button"
              onClick={() => logout()}
            >
              <ExitToAppIcon className="sidebar-menu-icon sidebar-menu-icon--left material-icons"></ExitToAppIcon>
              <span className="sidebar-menu-text">Logout</span>
            </Link>
          </>
        ) : (
          ""
        )}
      </Nav>
      <div
        className={`sidebar-p-a ${user_type === "advocate" ? "" : "d-none"}`}
      >
        <Link
          to="/subscription"
          className={`btn btn-primary handyy-primary-button ${
            isVAS ? "d-none" : ""
          }`}
          onClick={isMobile ? toggleSidebar : showSidebar}
        >
          Upgrade Your Account Now
        </Link>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton className="border-bottom-0 pr-4 pl-4">
            <Modal.Title>Add User</Modal.Title>
          </Modal.Header>
          <Modal.Body className="pt-3 pr-4 pl-4 mb-3">
            <Form>
              <div className="form-group">
                <input
                  id="email"
                  name="email"
                  className="form-control form-white"
                  placeholder="Email"
                  type="text"
                  onChange={handleInputChange}
                />
                <div>
                  <label htmlFor="type">Select Role</label>
                  <br />
                  <Form.Control
                    as="select"
                    name="type"
                    className="my-1 mr-sm-2"
                    id="type"
                    custom
                    onChange={handleInputChange}
                  >
                    <option value="support">Support</option>
                    <option value="administrator">Administrator</option>
                  </Form.Control>
                </div>
              </div>
            </Form>
            <Button
              className="btn btn-success ml-1"
              onClick={handleAddRole}
              disabled={!isFormValid}
            >
              Add
            </Button>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default Sidebar;
