import { RESTAPI } from "../../rest-api";
import { axiosGet, axiosPost } from "./http-request-service";

const GetAdvocateLists = (startDate = "", endDate = "") => {
  let params = {};
  if (startDate && endDate) {
    params["start_date"] = startDate;
    params["end_date"] = endDate;
  }
  return axiosGet(RESTAPI.GETADMINADVOCATELIST, params)
    .then((response) => {
      return response;
    })
    .catch((err) => {});
};

const UpdateAdvocateData = (data) => {
  return axiosPost(RESTAPI.UPDATEADVOCATEDATA, data)
    .then((response) => {
      return response;
    })
    .catch((err) => {});
};

const GetAdminRepotsData = (startDate = "", endDate = "") => {
  let params = {};
  if (startDate && endDate) {
    params["start_date"] = startDate;
    params["end_date"] = endDate;
  }

  return axiosGet(RESTAPI.GETADMINREPORTSDATA, params).then((response) => {
    return response;
  });
};

const AddRole = (data) => {
  return axiosPost(RESTAPI.ADDUSERROLE, data)
    .then((response) => {
      return response;
    })
    .catch((err) => {});
};

export { GetAdvocateLists, UpdateAdvocateData, GetAdminRepotsData, AddRole };
