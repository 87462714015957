import React, { useEffect, useState } from "react";
import "./App.css";
import Header from "./App/Shared-Components/header";
import Sidebar from "./App/Shared-Components/sidebar";
import { Container, Row, Col } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import Routes from "./routes";
import Login from "./App/Components/Authentication/Login";
import { useLocation } from "react-router-dom";
import { getTokenT, onMessageListener } from "./App/Services/firebase";
import { UpdateFCMToken } from "./App/Services/notification-service";
import { getFCMToken, setFCMToken } from "./App/Services/common-service";
import logo from "./App/assets/images/logo.png";

export const AuthContext = React.createContext();

const initialState = {
  isAuthenticated: false,
  user: null,
  loading: false
};

const reducer = (state, action) => {
  // console.log('state in reducer...', state);    
  switch (action.type) {
    case "LOGIN":
      // console.log(action.type);
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
        loading: false
      };
    case "LOGOUT":
      console.log("lOGOUT");
      localStorage.clear();
      return {
        ...state,
        isAuthenticated: false,
        user: action.payload,
        loading: false
      };
    case "LOGIN_PROCESS":
      return {
        ...state,
        isAuthenticated: false,
        user: action.payload,
        loading: true
      };
    default:
      return state;
  }
};

const App = () => {

  let location = useLocation();
  let showSidebar = true;
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [isCollapse, setCollapse] = useState(false);
  const handlerCollapse = () => setCollapse(!isCollapse);
  // console.log('state inside app...', state);

  const [show, setShow] = useState(false);
  const [notificationT, setNotification] = useState({ title: '', body: '' });

  const [isTokenFound, setTokenFound] = useState(false);
  const [token,setToken] = useState('')

  useEffect(()=>{
    getTokenT(setTokenFound, setToken);
  },[])

  useEffect(()=>{
    // console.log("app token", token);
    setFCMToken(token)
  },[token])

  onMessageListener().then(payload => {
    setShow(true);
    setNotification({ title: payload.notification.title, body: payload.notification.body })
    // console.log(payload);
  }).catch(err => console.log('failed: ', err));

  useEffect(() => {
    if (Notification.permission !== 'granted')
      Notification.requestPermission();

    if (notificationT.title !== '')
      var notification = new Notification(notificationT.title, {
        icon: logo,
        body: notificationT.body,
      });
  }, [notificationT])

  const toggleSidebar = () => {
    handlerCollapse();
  };

  let redirectSet = false;
  let redirectVal = "/";

  if (location.pathname === "/") {
    redirectSet = true;
    let handyyUser = localStorage.getItem("handyyUser");
    try {

      if (state.isAuthenticated && handyyUser) {
        handyyUser = JSON.parse(handyyUser);
        if (handyyUser.user_type === "advocate") {
          var data = { fcm_token: getFCMToken() }

          UpdateFCMToken(data).then(res => {
          }).catch(err => console.log(err))
          
          if (handyyUser.subscription_type === 'Basic') {
            redirectVal = '/subscription';
          } else {
            redirectVal = "/home";
          }
          showSidebar = true;
        } else if (handyyUser.user_type === "support") {
          redirectVal = "/support-dashboard";
          showSidebar = false;
        } else {
          redirectVal = "/admin-dashboard";
          showSidebar = false;
        }
      } else {
        // console.log('handyy user nhi mil rha');
      }
    } catch {
      // console.log('try catch me aya...');
      redirectVal = "/";
    }
  }

  if (location.pathname !== "/") {
    let handyyUser = localStorage.getItem("handyyUser");
    handyyUser = JSON.parse(handyyUser);
    try {
      if (
        state.isAuthenticated &&
        handyyUser !== undefined &&
        handyyUser.user_type !== "advocate"
      ) {
        showSidebar = false;
      } else {
        showSidebar = true;
      }
    } catch {
      showSidebar = true;
    }
  }

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      <div className="App full-height">
        {
          !state.isAuthenticated && !state.user
            ? (<Login></Login>)
            : redirectSet ? (
              <Redirect push to={redirectVal} />
              // console.log("redirect and state, redirectVal", redirectSet, state, redirectVal)        
            )
              : !showSidebar ? (
                <>
                  <Header toggleSidebar={toggleSidebar}></Header>
                  <Container fluid className="handyy-container-background">
                    <Row>
                      {window.innerWidth <= 575 ? (
                        <>
                          <Col md={3} lg={3} className="sidebar-custom-col-3">
                            <div
                              id="handyy-sidebar"
                              className={`handyy-sidebar ${isCollapse ? "has-drawer-opened" : ""
                                }`}
                            >
                              <Sidebar
                                toggleSidebar={toggleSidebar}
                                isMobile={isCollapse}
                              ></Sidebar>
                            </div>
                          </Col>
                          <div
                            style={{ display: "none" }}
                            className={`mdk-drawer__scrim ${isCollapse ? "mdk-drawer__scrim_opacity" : ""
                              }`}
                          ></div>
                        </>
                      ) : (
                        ""
                      )}
                      <Col className="col-without-sidebar">
                        <Routes></Routes>
                      </Col>
                    </Row>
                  </Container>
                </>
              ) : (
                <>
                  <Header toggleSidebar={toggleSidebar}></Header>
                  <Container fluid className="handyy-container">
                    <Row>
                      <Col md={3} lg={3} className="sidebar-custom-col-3">
                        <div
                          id="handyy-sidebar"
                          className={`handyy-sidebar ${isCollapse ? "has-drawer-opened" : ""
                            }`}
                        >
                          <Sidebar
                            toggleSidebar={toggleSidebar}
                            isMobile={isCollapse}
                          ></Sidebar>
                        </div>
                      </Col>
                      <div
                        style={{ display: "none" }}
                        className={`mdk-drawer__scrim ${isCollapse ? "mdk-drawer__scrim_opacity" : ""
                          }`}
                      ></div>
                      <Col sm={12} md={9} lg={9} className="handyy-custom-main-col">
                        <Routes></Routes>
                      </Col>
                    </Row>
                  </Container>
                </>
              )}
      </div>
    </AuthContext.Provider>
  );
};

export default App;
