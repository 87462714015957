import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { setFCMToken } from './common-service';

const firebaseConfig = {
    apiKey: "AIzaSyAkN9d-ZZoy3r-OYlNWYJ0BNTyKGVekQWw",
    authDomain: "handyy-notifications.firebaseapp.com",
    projectId: "handyy-notifications",
    storageBucket: "handyy-notifications.appspot.com",
    messagingSenderId: "307551866037",
    appId: "1:307551866037:web:60eb9e9d19426e6b81c2fc",
    measurementId: "G-KJ21YN6MBZ"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const getTokenT = (setTokenFound,setToken) => {
    return getToken(messaging, {
        vapidKey: 'BCuOreXMXNvSBAP8IavX4rI2j9cewyzYVpfdyh29wclYfjWrINdDznwse0F8YDpG-x08PFhNjq_v2udxddc9Pc4'
    }).then((currentToken) => {
        if (currentToken) {
            setFCMToken(currentToken)
            setToken(currentToken)
            console.log('current token for client: ', currentToken);
            setTokenFound(true);
            // Track the token -> client mapping, by sending to backend server
            // show on the UI that permission is secured
        } else {
            console.log('No registration token available. Request permission to generate one.');
            setTokenFound(false);
            // shows on the UI that permission is required 
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // catch error while creating client token
    });
}

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });