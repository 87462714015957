/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
const awsmobile = {
  aws_project_region: "ap-south-1",
  aws_cognito_identity_pool_id:
    "ap-south-1:52a376a4-9255-42fa-a2f5-69c0a4b365ed",
  aws_cognito_region: "ap-south-1",
  aws_user_pools_id: "ap-south-1_WIQaIBIAS",
  aws_user_pools_web_client_id: "6513en1dgjfkndcit4gimnk3d5",
  oauth: {
    domain: "auth.bar-at.in",
    scope: ["email", "openid", "profile"],
    redirectSignIn: "https://handyytest.bar-at.in/",
    redirectSignOut: "https://handyytest.bar-at.in/",
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_POOLS",
};
export default awsmobile;
